var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper"},[_c('svg',{ref:"svg",staticClass:"svg-background",class:{ 'svg-margin-bottom': _vm.disabledTitle },style:({
      '--margin-title': `${_vm.marginTitle}px`,
      '--color-secondary': _vm.config.disenio.datos.color_secundario,
    }),attrs:{"viewBox":"0 0 800 118","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0,135 Q800,60 700,300 L1050,0 L0,0 Z","fill":_vm.config.disenio.datos.color_primario}}),_c('path',{attrs:{"d":"M0,95 Q600,150 900,50 L950,280 L0,180 Z","fill":_vm.config.disenio.datos.color_secundario}}),_c('path',{attrs:{"d":"M0,100 Q450,139 800,85 L900,600 L10,920 Z","fill":_vm.config.disenio.datos.color_fondo}})]),_c('div',{staticClass:"content-container",class:{ 'color-title': _vm.disabledTitle },style:({
      '--height-title': `${_vm.heightTitle}px`,
      '--color-secondary': _vm.config.disenio.datos.color_secundario,
    })},[_c('div',{staticClass:"container title",style:({
        '--color-secondary': _vm.config.disenio.datos.color_secundario,
      })},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"rutas personalizado text-end m-0",style:({
              '--color-secondary': _vm.config.disenio.datos.color_secundario,
            })})]),_c('div',{ref:"areaTitulo",staticClass:"col-md-12 m-0 titulo-class"},[_vm._t("area-titulo")],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }