<template>
  <div class="page-wrapper">
    <!-- Contenedor con contenido encima del SVG -->

    <svg
      ref="svg"
      viewBox="0 0 800 118"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-background"
      :class="{ 'svg-margin-bottom': disabledTitle }"
      :style="{
        '--margin-title': `${marginTitle}px`,
        '--color-secondary': config.disenio.datos.color_secundario,
      }"
    >
      <!-- Sección verde (más grande) -->
      <path
        d="M0,135 Q800,60 700,300 L1050,0 L0,0 Z"
        :fill="config.disenio.datos.color_primario"
      />
      <!-- Sección azul clara (segunda curva) -->
      <path
        d="M0,95 Q600,150 900,50 L950,280 L0,180 Z"
        :fill="config.disenio.datos.color_secundario"
      />
      <!-- Sección amarilla (nueva cuarta curva) -->
      <path
        d="M0,100 Q450,139 800,85 L900,600 L10,920 Z"
        :fill="config.disenio.datos.color_fondo"
      />
    </svg>
    <div
      class="content-container"
      :class="{ 'color-title': disabledTitle }"
      :style="{
        '--height-title': `${heightTitle}px`,
        '--color-secondary': config.disenio.datos.color_secundario,
      }"
    >
      <div
        class="container title"
        :style="{
          '--color-secondary': config.disenio.datos.color_secundario,
        }"
      >
        <div class="row">
          <div class="col-md-12">
            <div
              class="rutas personalizado text-end m-0"
              :style="{
                '--color-secondary': config.disenio.datos.color_secundario,
              }"
            >
              <!--<slot name="area-rutas"></slot>-->
            </div>
          </div>
          <div ref="areaTitulo" class="col-md-12 m-0 titulo-class">
            <slot name="area-titulo"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scrollPosition: null,
      heightTitle: 0,
      disabledTitle: false,
      marginTitle: 0,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    async calcHieght(tamanio) {
      const areaTitulo = this.$refs.areaTitulo;
      const altura = areaTitulo.offsetHeight;
      let tamanioFinal = 0;
      let marginTitle = 0;
      this.disabledTitle = false;
      if (tamanio.height > 206.5)
        tamanioFinal = Math.round(tamanio.height * 1.5);
      else if (tamanio.height <= 206.5 && tamanio.height > 150)
        tamanioFinal = Math.round(tamanio.height * 1.4);
      else if (tamanio.height <= 150 && tamanio.height > 140)
        tamanioFinal = Math.round(tamanio.height * 1.3);
      else {
        tamanioFinal = Math.round(tamanio.height * 2.7);
        this.disabledTitle = true;
        marginTitle = altura + 10;
      }
      if (tamanioFinal <= 305 && marginTitle > 60 && marginTitle <= 104) {
        tamanioFinal = Math.round(tamanio.height * 4.0);
        marginTitle += 20;
      } else if (
        tamanioFinal <= 305 &&
        marginTitle > 104 &&
        marginTitle <= 130
      ) {
        tamanioFinal = Math.round(tamanio.height * 3.4);
        marginTitle += 25;

      } else if (tamanioFinal <= 305 && marginTitle > 130) {
        tamanioFinal = Math.round(tamanio.height * 5.4);
        marginTitle += 35;
      }
      return { tamanioFinal, marginTitle };
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },

  async created() {
    const svg = this.$refs.svg;
    if (!(svg instanceof Node)) return;
    const tamanio = svg.getBoundingClientRect();
    const data = await this.calcHieght(tamanio);
    this.marginTitle = data.marginTitle;
    this.heightTitle = data.tamanioFinal;
  },

  async mounted() {
    setTimeout(async () => {
      const svg = this.$refs.svg;
      if (!(svg instanceof Node)) return;
      const tamanio = svg.getBoundingClientRect();
      const data = await this.calcHieght(tamanio);
      this.marginTitle = data.marginTitle;
      this.heightTitle = data.tamanioFinal;
    }, 1000);

    window.addEventListener("scroll", this.updateScroll);
    window.addEventListener("resize", async () => {
      const svg = this.$refs.svg;
      if (svg instanceof Node) {
        const tamanio = svg.getBoundingClientRect();
        const data = await this.calcHieght(tamanio);

        this.heightTitle = data.tamanioFinal;
        this.marginTitle = data.marginTitle;
      }
    });
  },
};
</script>
<style>
page-wrapper {
  position: relative;
  width: 100%;
}

.svg-background {
  width: 100%;
  margin-bottom: 0px;
}

.svg-margin-bottom {
  margin-bottom: var(--margin-title) !important;
}

.color-title {
  color: var(--color-secondary) !important;
}

.content-container {
  display: flex;
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: center; /* Centra el contenido horizontalmente */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--height-title); /* Alinea con la altura del SVG */
  /*  z-index: 2; */
  color: #fff;
}

/* Ajustes de estilo para el título */
.title {
  text-align: center;
  font-size: 0.8rem !important;
}

.title h2,
.title h3,
.title h4 {
  text-align: center;
}

.personalizado {
  color: #fff;
  font-size: 0.7rem;
}
.personalizado a {
  color: var(--color-secondary) !important;
}
</style>
