import { render, staticRenderFns } from "./TituloSeccion.vue?vue&type=template&id=5e3cb0a6"
import script from "./TituloSeccion.vue?vue&type=script&lang=js"
export * from "./TituloSeccion.vue?vue&type=script&lang=js"
import style0 from "./TituloSeccion.vue?vue&type=style&index=0&id=5e3cb0a6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports